<template>
  <section class="showcase-section">
    <div class="background">
      <img
        :src="require('@/assets/background2.jpg')"
        alt="Showcase Background"
      />
    </div>
    <div class="content">
      <h2 class="section-title">{{ $t("sections.sendInquiry.title") }}</h2>
      <p class="section-text">{{ $t("sections.sendInquiry.text") }}</p>
      <button @click="scrollToContact">{{ $t("navigation.section2") }}</button>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToContact() {
      const contactSection = document.getElementById("section6");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.showcase-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.content {
  position: absolute;
  text-align: center;
  color: white;
  max-width: 600px;
}
.section-title {
  font-size: 36px;
  margin-bottom: 20px;
}
.section-text {
  font-size: 18px;
  margin-bottom: 30px;
}
button {
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #004999;
}
</style>
