<template>
  <section class="brilliance-section">
    <div class="background">
      <img
        :src="require('@/assets/background3.jpg')"
        alt="Brilliance Background"
      />
    </div>
    <div class="content">
      <h2 class="section-title">{{ $t("sections.whoWeAre.title") }}</h2>
      <p class="section-text">{{ $t("sections.whoWeAre.text") }}</p>
      <div class="customer-focus">
        <p>{{ $t("sections.customerFocus.line1") }}</p>
        <p>{{ $t("sections.customerFocus.line2") }}</p>
        <p>{{ $t("sections.customerFocus.line3") }}</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.brilliance-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.content {
  text-align: center;
  color: white;
  max-width: 800px;
  padding: 15px; /* Add padding for better spacing */
}

.section-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.section-text {
  font-size: 18px;
  margin-bottom: 30px;
}

.customer-focus {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
}

.customer-focus h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.customer-focus p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .brilliance-section {
    padding: 10px;
  }

  .content {
    padding: 10px;
    max-width: 100%;
    margin: 0 10px;
  }

  .customer-focus {
    font-size: 14px;
  }
}
</style>
