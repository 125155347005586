<template>
  <div class="home">
    <SectionHeader @scrollToSection="scrollToSection" />
    <Section0Hero id="section0" />
    <Section1Features id="section1" />
    <Section2Showcase id="section2" />
    <Section3Brilliance id="section3" />
    <Section4Works id="section4" />
    <Section5MobileFirst id="section5" />
    <Section6Contact id="section6" />
    <SectionFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SectionHeader from "@/components/SectionHeader.vue";
import Section0Hero from "@/components/Section0Hero.vue";
import Section1Features from "@/components/Section1Features.vue";
import Section2Showcase from "@/components/Section2Showcase.vue";
import Section3Brilliance from "@/components/Section3Brilliance.vue";
import Section4Works from "@/components/Section4Works.vue";
import Section5MobileFirst from "@/components/Section5MobileFirst.vue";
import Section6Contact from "@/components/Section6Contact.vue";
import SectionFooter from "@/components/SectionFooter.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    SectionHeader,
    Section0Hero,
    Section1Features,
    Section2Showcase,
    Section3Brilliance,
    Section4Works,
    Section5MobileFirst,
    Section6Contact,
    SectionFooter,
  },
  methods: {
    scrollToSection(sectionId: string) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    // Scroll to section0 on page load
    this.scrollToSection("section0");
  },
});
</script>

<style scoped>
/* Ensure body and html take full height */
body,
html {
  height: 100%;
  margin: 0;
}

html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

body {
  overflow: hidden; /* Prevent default scrollbars */
}

.home {
  scroll-snap-type: y mandatory; /* Enable vertical scroll snapping */
  overflow-y: scroll; /* Make .home scrollable */
  height: 100vh;
}

/* Remove padding-top to eliminate weird space */
.home {
  /* padding-top: 60px; */
}

section {
  min-height: 100vh; /* Ensures each section takes up at least full screen */
  scroll-snap-align: start; /* Snap sections to the start */
  position: relative;
}

/* Adjust content to account for fixed header */
section > * {
  padding-top: 75px; /* Adjust based on header height */
  box-sizing: border-box;
}

/* Adjust header z-index if necessary */
header.header {
  z-index: 1000;
}
</style>
