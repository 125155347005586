import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_Section0Hero = _resolveComponent("Section0Hero")!
  const _component_Section1Features = _resolveComponent("Section1Features")!
  const _component_Section2Showcase = _resolveComponent("Section2Showcase")!
  const _component_Section3Brilliance = _resolveComponent("Section3Brilliance")!
  const _component_Section4Works = _resolveComponent("Section4Works")!
  const _component_Section5MobileFirst = _resolveComponent("Section5MobileFirst")!
  const _component_Section6Contact = _resolveComponent("Section6Contact")!
  const _component_SectionFooter = _resolveComponent("SectionFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionHeader, { onScrollToSection: _ctx.scrollToSection }, null, 8, ["onScrollToSection"]),
    _createVNode(_component_Section0Hero, { id: "section0" }),
    _createVNode(_component_Section1Features, { id: "section1" }),
    _createVNode(_component_Section2Showcase, { id: "section2" }),
    _createVNode(_component_Section3Brilliance, { id: "section3" }),
    _createVNode(_component_Section4Works, { id: "section4" }),
    _createVNode(_component_Section5MobileFirst, { id: "section5" }),
    _createVNode(_component_Section6Contact, { id: "section6" }),
    _createVNode(_component_SectionFooter)
  ]))
}