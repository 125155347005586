<template>
  <footer class="footer-section">
    <div class="footer-content">
      <p>&copy; 2024 ProFinance Consulting. All rights reserved. 7753189428</p>
      <ul class="social-links">
        <li>
          <a href="#"><i class="fab fa-facebook-f"></i></a>
        </li>
        <li>
          <a href="#"><i class="fab fa-twitter"></i></a>
        </li>
        <li>
          <a href="#"><i class="fab fa-linkedin-in"></i></a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SectionFooter",
};
</script>

<style scoped>
.footer-section {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-content p {
  margin: 0;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 15px;
  padding: 0;
}

.social-links li {
  display: inline-block;
}

.social-links li a {
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.social-links li a:hover {
  color: #ffd700;
}
</style>
