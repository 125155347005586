<template>
  <section class="mobilefirst-section">
    <div class="background">
      <img
        :src="require('@/assets/background5.jpg')"
        alt="Strengths Background"
      />
    </div>
    <div class="content">
      <!--<h2 class="section-title">{{ $t("sections.strengths.title") }}</h2>-->
      <div class="strengths-grid">
        <div class="strength-item">
          <h3>{{ $t("sections.strengths.precise.title") }}</h3>
          <p>{{ $t("sections.strengths.precise.text") }}</p>
        </div>
        <div class="strength-item">
          <h3>{{ $t("sections.strengths.reliable.title") }}</h3>
          <p>{{ $t("sections.strengths.reliable.text1") }}</p>
          <p>{{ $t("sections.strengths.reliable.text2") }}</p>
          <p>{{ $t("sections.strengths.reliable.text3") }}</p>
        </div>
        <div class="strength-item">
          <h3>{{ $t("sections.strengths.professional.title") }}</h3>
          <p>{{ $t("sections.strengths.professional.text") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.mobilefirst-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  overflow-x: hidden;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.content {
  text-align: center;
  color: white;
  max-width: 800px;
}

.strengths-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.strength-item {
  background: rgba(255, 255, 255, 0.7);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
}

h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .mobilefirst-section {
    padding: 10px;
  }

  .strengths-grid {
    grid-template-columns: 1fr; /* Stack the grid items on smaller screens */
    gap: 15px;
  }

  .strength-item {
    padding: 10px;
    font-size: 14px;
  }
}
</style>
