<template>
  <section class="works-section">
    <div class="background">
      <img :src="require('@/assets/background4.jpg')" alt="Works Background" />
    </div>
    <div class="content">
      <h2 class="section-title">
        {{ $t("sections.discoverDifference.title") }}
      </h2>
      <p class="section-text">
        {{ $t("sections.discoverDifference.subtitle") }}
      </p>
    </div>
  </section>
</template>

<style scoped>
.works-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  overflow-x: hidden;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #333;
  max-width: 700px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.section-text {
  font-size: 18px;
}

@media (max-width: 768px) {
  .works-section {
    padding: 10px;
  }

  .content {
    padding: 15px;
    max-width: 100%;
    margin: 0 10px;
  }
}
</style>
