<template>
  <section class="hero-section">
    <video autoplay loop muted playsinline>
      <source src="@/assets/background0.mp4" type="video/mp4" />
    </video>
    <div class="content">
      <h1>{{ $t("sections.hero.heading1") }}</h1>
      <h1>{{ $t("sections.hero.heading2") }}</h1>
    </div>
  </section>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Yu+Gothic&display=swap"); /* Importing Yu Gothic from Google Fonts */

/* Ensure the entire section takes up the full viewport height */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Ensure the video covers the entire section */
.hero-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure the video is behind the content */
}

/* Center the content and apply the Yu Gothic font */
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-family: "Yu Gothic", sans-serif; /* Applying Yu Gothic */
  z-index: 1;
}

/* Styling specific to the h1 elements */
.content h1 {
  margin: 10px 0;
  font-size: 42px; /* Default font size for larger screens */
  letter-spacing: 2px; /* Optional: Letter spacing for a modern feel */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflow if the text is too long */
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
}

/* Responsive typography for smaller screens */
@media (max-width: 1200px) {
  .content h1 {
    font-size: 34px; /* Adjust font size for tablets and medium screens */
  }
}

@media (max-width: 992px) {
  .content h1 {
    font-size: 30px; /* Adjust font size for smaller tablets */
  }
}

@media (max-width: 768px) {
  .content h1 {
    font-size: 26px; /* Adjust font size for mobile devices */
  }
}

@media (max-width: 576px) {
  .content h1 {
    font-size: 22px; /* Smaller font size for very small screens */
  }
}
</style>
