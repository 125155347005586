import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "contact-section",
  id: "section6"
}
const _hoisted_2 = { class: "background" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "contact-info-grid" }
const _hoisted_5 = { class: "contact-info-item" }
const _hoisted_6 = { class: "contact-info-item" }
const _hoisted_7 = { class: "contact-info-item" }
const _hoisted_8 = { class: "form-title" }
const _hoisted_9 = { class: "form-row" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { type: "submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/background6.png'),
        alt: "Contact Background"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "mdi mdi-map-marker-circle" }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("sections.contact.companyName")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("sections.contact.address")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("sections.contact.uid")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("i", { class: "mdi mdi-phone" }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("sections.contact.phone")), 1),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, "office@profinance-consulting.at", -1))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[8] || (_cache[8] = _createElementVNode("i", { class: "mdi mdi-information" }, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("sections.contact.personalConsultation1")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("sections.contact.personalConsultation2")), 1)
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
      class: "contact-form"
    }, [
      _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t("navigation.section6")), 1),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            id: "name",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            placeholder: _ctx.$t('contactForm.name'),
            required: ""
          }, null, 8, _hoisted_11), [
            [_vModelText, _ctx.name]
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _withDirectives(_createElementVNode("input", {
            type: "email",
            id: "email",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
            placeholder: _ctx.$t('contactForm.email'),
            required: ""
          }, null, 8, _hoisted_13), [
            [_vModelText, _ctx.email]
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _withDirectives(_createElementVNode("input", {
            type: "tel",
            id: "tel",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tel) = $event)),
            placeholder: _ctx.$t('contactForm.tel')
          }, null, 8, _hoisted_15), [
            [_vModelText, _ctx.tel]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _withDirectives(_createElementVNode("textarea", {
          id: "message",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.message) = $event)),
          placeholder: _ctx.$t('contactForm.message'),
          rows: "5",
          required: ""
        }, null, 8, _hoisted_17), [
          [_vModelText, _ctx.message]
        ])
      ]),
      _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$t("contactForm.submit")), 1)
    ], 32)
  ]))
}