<template>
  <header class="header">
    <div class="logo" @click.prevent="scrollTo('section0')">
      <img :src="require('@/assets/logo.png')" alt="ProFinance Logo" />
    </div>
    <div class="menu-icon" @click="toggleMenu">
      <!-- Hamburger icon -->
      <div :class="['hamburger', isMenuOpen ? 'open' : '']"></div>
    </div>
    <nav :class="{ open: isMenuOpen }">
      <ul>
        <!--
        <li>
          <a @click.prevent="scrollTo('section0')">{{
            $t("navigation.section0")
          }}</a>
        </li>
        -->
        <li>
          <a @click.prevent="scrollTo('section1')">{{
            $t("navigation.section1")
          }}</a>
        </li>
        <li>
          <a @click.prevent="scrollTo('section2')">{{
            $t("navigation.section2")
          }}</a>
        </li>
        <li>
          <a @click.prevent="scrollTo('section3')">{{
            $t("navigation.section3")
          }}</a>
        </li>
        <!--
        <li>
          <a @click.prevent="scrollTo('section4')">{{
            $t("navigation.section4")
          }}</a>
        </li>
        -->
        <!--
        <li>
          <a @click.prevent="scrollTo('section5')">{{
            $t("navigation.section5")
          }}</a>
        </li>
        -->
        <li>
          <a @click.prevent="scrollTo('section6')">{{
            $t("navigation.section6")
          }}</a>
        </li>
        <li class="language-dropdown">
          <a href="#" @click.prevent="toggleLanguageMenu">{{
            $t("navigation.changeLanguage")
          }}</a>
          <ul v-if="showLanguageMenu">
            <li @click="changeLanguage('en')">English</li>
            <li @click="changeLanguage('de')">Deutsch</li>
            <li @click="changeLanguage('hu')">Magyar</li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SectionHeader",
  data() {
    return {
      showLanguageMenu: false,
      isMenuOpen: false,
    };
  },
  methods: {
    scrollTo(sectionId: string) {
      this.$emit("scrollToSection", sectionId);
      this.isMenuOpen = false; // Close the menu after clicking a link
    },
    toggleLanguageMenu() {
      this.showLanguageMenu = !this.showLanguageMenu;
    },
    changeLanguage(lang: string) {
      this.$i18n.locale = lang;
      this.showLanguageMenu = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
});
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #ffd700;
}

/* Styles for the language dropdown menu */
.language-dropdown {
  position: relative;
}

.language-dropdown ul {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px;
  list-style: none;
  margin: 0;
  display: block;
}

.language-dropdown ul li {
  padding: 5px 10px;
  cursor: pointer;
}

.language-dropdown ul li:hover {
  background-color: #444;
}

/* Hamburger icon styles */
.menu-icon {
  display: none;
  cursor: pointer;
}

.hamburger {
  width: 25px;
  height: 2px;
  background-color: white;
  position: relative;
  transition: all 0.3s ease;
}

.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburger:before {
  top: -8px;
}

.hamburger:after {
  top: 8px;
}

/* Hamburger animation when open */
.hamburger.open {
  background-color: transparent;
}

.hamburger.open:before {
  transform: rotate(45deg);
  top: 0;
}

.hamburger.open:after {
  transform: rotate(-45deg);
  top: 0;
}

/* Mobile menu */
nav {
  display: flex;
}

@media (max-width: 768px) {
  nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: none;
    flex-direction: column;
    padding: 20px;
  }

  nav.open {
    display: block;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul li {
    margin-bottom: 15px;
  }

  .menu-icon {
    display: block;
  }
}
</style>
