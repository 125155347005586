<template>
  <section class="contact-section" id="section6">
    <div class="background">
      <img
        :src="require('@/assets/background6.png')"
        alt="Contact Background"
      />
    </div>
    <div class="contact-info-grid">
      <div class="contact-info-item">
        <i class="mdi mdi-map-marker-circle"></i>
        <p>{{ $t("sections.contact.companyName") }}</p>
        <p>{{ $t("sections.contact.address") }}</p>
        <p>{{ $t("sections.contact.uid") }}</p>
      </div>
      <div class="contact-info-item">
        <i class="mdi mdi-phone"></i>
        <p>{{ $t("sections.contact.phone") }}</p>
        <p>office&#64;profinance-consulting.at</p>
      </div>
      <div class="contact-info-item">
        <i class="mdi mdi-information"></i>
        <p>{{ $t("sections.contact.personalConsultation1") }}</p>
        <p>{{ $t("sections.contact.personalConsultation2") }}</p>
      </div>
    </div>

    <!-- Form Title -->

    <form @submit.prevent="submitForm" class="contact-form">
      <h2 class="form-title">{{ $t("navigation.section6") }}</h2>

      <div class="form-row">
        <div class="form-group">
          <input
            type="text"
            id="name"
            v-model="name"
            :placeholder="$t('contactForm.name')"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            id="email"
            v-model="email"
            :placeholder="$t('contactForm.email')"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="tel"
            id="tel"
            v-model="tel"
            :placeholder="$t('contactForm.tel')"
          />
        </div>
      </div>
      <div class="form-group">
        <textarea
          id="message"
          v-model="message"
          :placeholder="$t('contactForm.message')"
          rows="5"
          required
        ></textarea>
      </div>
      <button type="submit">{{ $t("contactForm.submit") }}</button>
    </form>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

export default defineComponent({
  name: "Section6Contact",
  data() {
    return {
      name: "",
      email: "",
      tel: "",
      message: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        console.log({
          name: this.name,
          email: this.email,
          tel: this.tel,
          message: this.message,
        });

        // Send the form data via Axios
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/contact`,
          {
            name: this.name,
            email: this.email,
            tel: this.tel,
            message: this.message,
          }
        );
        console.log(response.data);

        // Fire Google Analytics event for successful form submission
        if (window.gtag) {
          console.log("gtag exists, firing event");
          window.gtag("event", "contact_form_submission", {
            event_category: "Contact",
            event_label: "Contact Form Submission",
            value: 1,
          });

          // Fire conversion event (Google Ads)
          window.gtag("event", "conversion", {
            send_to: "AW-16716141963/jJK4CLD13tYZEIur8KI-",
            value: 1.0, // You can dynamically set the conversion value
            currency: "EUR", // Set your preferred currency
          });
        } else {
          console.log("gtag is not defined");
        }

        // Reset the form fields after submission
        this.name = "";
        this.email = "";
        this.tel = "";
        this.message = "";
        alert("Your message has been sent successfully!");
      } catch (error) {
        console.error(error);
        alert("There was an error sending your message.");
      }
    },
  },
});
</script>

<style scoped>
.contact-section {
  position: relative;
  width: 100%;
  padding: 20px 0;
  color: white;
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.contact-info-grid {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  max-width: 1200px;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .contact-info-grid {
    flex-direction: column;
    align-items: center;
  }

  .contact-info-item {
    min-width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .contact-info-item p {
    font-size: 14px; /* Reduced font size of <p> elements on mobile */
  }

  .contact-info-item i {
    font-size: 20px; /* Reduced icon size on mobile */
    margin-bottom: 5px;
  }
}

.contact-info-item {
  flex: 0 0 auto;
  min-width: 200px;
  text-align: center;
  font-size: 18px;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-info-item i {
  font-size: 48px;
  display: block;
  margin-bottom: 10px;
  color: #3ec1ff;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  padding-right: 3vw;
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .contact-form {
    padding: 20px;
    max-width: 100%;
    margin: 0 10px;
    padding-right: 0;
    padding-left: 0;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    font-size: 16px;
    padding: 15px;
  }

  button {
    width: 100%;
    max-width: 300px;
  }

  .form-title {
    font-size: 24px;
  }
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  background-color: #333;
  border: none;
  padding: 18px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
}

.form-group textarea {
  min-height: 150px;
}

input::placeholder,
textarea::placeholder {
  color: #ccc;
}

button {
  padding: 15px 40px;
  background-color: #00d1ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

button:hover {
  background-color: #00a3d8;
}

.form-title {
  font-size: 28px;
  color: white;
  margin-bottom: 20px;
}
</style>
