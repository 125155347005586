<template>
  <section class="features-section">
    <div class="background">
      <img
        :src="require('@/assets/background1.jpg')"
        alt="Features Background"
      />
    </div>
    <div class="content">
      <h2 class="section-title">{{ $t("navigation.section1") }}</h2>
      <div class="features-grid">
        <!-- Consulting Feature -->
        <div class="feature-item">
          <h3>{{ $t("sections.consulting.title") }}</h3>
          <p>{{ $t("sections.consulting.subtitle1") }}</p>
          <p>{{ $t("sections.consulting.subtitle2") }}</p>
        </div>
        <!-- Accounting Feature -->
        <div class="feature-item">
          <h3>{{ $t("sections.accounting.title") }}</h3>
          <p>{{ $t("sections.accounting.subtitle1") }}</p>
          <p>{{ $t("sections.accounting.subtitle2") }}</p>
          <p>{{ $t("sections.accounting.subtitle3") }}</p>
        </div>
        <!-- EU-OSS Service Feature -->
        <div class="feature-item">
          <h3>{{ $t("sections.euOssService.title") }}</h3>
          <p>{{ $t("sections.euOssService.subtitle1") }}</p>
          <p>{{ $t("sections.euOssService.subtitle2") }}</p>
        </div>
        <!-- Tax Equalization Feature -->
        <div class="feature-item">
          <h3>{{ $t("sections.taxEqualization.title") }}</h3>
          <p>{{ $t("sections.taxEqualization.subtitle1") }}</p>
          <p>{{ $t("sections.taxEqualization.subtitle2") }}</p>
        </div>
        <!-- Official Procedures Feature -->
        <div class="feature-item">
          <h3>{{ $t("sections.officialProcedures.title") }}</h3>
          <p>{{ $t("sections.officialProcedures.subtitle1") }}</p>
          <p>{{ $t("sections.officialProcedures.subtitle2") }}</p>
        </div>
        <!-- Translations Feature -->
        <div class="feature-item">
          <h3>{{ $t("sections.translations.title") }}</h3>
          <p>{{ $t("sections.translations.subtitle1") }}</p>
          <p>{{ $t("sections.translations.subtitle2") }}</p>
          <p>{{ $t("sections.translations.subtitle3") }}</p>
          <p>{{ $t("sections.translations.subtitle4") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.features-section {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.content {
  padding: 20px;
  color: white; /* Text color for readability */
}
.section-title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}
.features-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* 3 columns */
  gap: 20px;
  max-width: 1200px; /* Limits the maximum width */
  margin: 0 auto; /* Centers the grid */
}

@media (max-width: 1200px) {
  .features-grid {
    grid-template-columns: repeat(
      2,
      minmax(0, 1fr)
    ); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }
}

.feature-item {
  background: rgba(
    255,
    255,
    255,
    0.7
  ); /* Semi-transparent white background with slight opacity */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
}
h3 {
  font-size: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  margin: 10px 0;
}
</style>
