import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "language-dropdown" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", {
      class: "logo",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.scrollTo('section0')), ["prevent"]))
    }, [
      _createElementVNode("img", {
        src: require('@/assets/logo.png'),
        alt: "ProFinance Logo"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", {
      class: "menu-icon",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(['hamburger', _ctx.isMenuOpen ? 'open' : ''])
      }, null, 2)
    ]),
    _createElementVNode("nav", {
      class: _normalizeClass({ open: _ctx.isMenuOpen })
    }, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.scrollTo('section1')), ["prevent"]))
          }, _toDisplayString(_ctx.$t("navigation.section1")), 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.scrollTo('section2')), ["prevent"]))
          }, _toDisplayString(_ctx.$t("navigation.section2")), 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.scrollTo('section3')), ["prevent"]))
          }, _toDisplayString(_ctx.$t("navigation.section3")), 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.scrollTo('section6')), ["prevent"]))
          }, _toDisplayString(_ctx.$t("navigation.section6")), 1)
        ]),
        _createElementVNode("li", _hoisted_3, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleLanguageMenu && _ctx.toggleLanguageMenu(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t("navigation.changeLanguage")), 1),
          (_ctx.showLanguageMenu)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                _createElementVNode("li", {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeLanguage('en')))
                }, "English"),
                _createElementVNode("li", {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeLanguage('de')))
                }, "Deutsch"),
                _createElementVNode("li", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeLanguage('hu')))
                }, "Magyar")
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2)
  ]))
}