import { createI18n } from "vue-i18n";

// Import locale messages
import en from "./locales/en.json";
import de from "./locales/de.json";
import hu from "./locales/hu.json";

const messages = {
  en,
  de,
  hu,
};

// Get the browser's default language (e.g., "en-US" or "de-DE")
// and fallback to 'en' if not available.
const browserLocale = navigator.language.split("-")[0]; // Extract 'en', 'de', 'hu', etc.
const availableLocales = Object.keys(messages); // ["en", "de", "hu"]

// Set default to 'en' if the browser's locale is not available in your locales
const defaultLocale = availableLocales.includes(browserLocale)
  ? browserLocale
  : "en";

const i18n = createI18n({
  legacy: false, // You need this line to use Composition API
  locale: defaultLocale, // Set the locale dynamically based on the browser
  fallbackLocale: "en",
  globalInjection: true, // Allows you to use $t in templates without importing
  messages,
});

export default i18n;
