<template>
  <div id="app">
    <HomeView />
  </div>
</template>

<script>
import HomeView from "./views/HomeView.vue";
import "@mdi/font/css/materialdesignicons.css";

export default {
  name: "App",
  components: {
    HomeView,
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

html {
  scroll-behavior: smooth;
}
</style>
